<template>
  <div class="row no-gutters">
    <!-- Brand image -->
    <router-link :to="`/shop-by-brands/${model.slug}-official-store-${model.id}`" class="col-md-12">
      <b-img-lazy fluid center rounded
        :src="model.image_url"
        :title="model.name"
        :alt="model.name"
      />
    </router-link>
    <!-- Highlight product -->
    <div class="row no-gutters col-md-12 mt-2">
      <div class="col px-1" v-for="(highlight, i) in [...model.highlight_product, ...Array(3 - model.highlight_product.length).fill(null)]" :key="i">
        <!-- if highlight image url is null -->
        <router-link v-if="!highlight" :to="`/shop-by-brands/${model.slug}-official-store-${model.id}`" class="h-100 d-flex align-items-stretch">
          <b-img :src="require('@/assets/img/default-image.png')" alt="" fluid class="rounded p-3 p-lg-4 default-image" />
        </router-link>
        <!-- else if not null -->
        <template v-else>
          <!-- if url contains whizliz.com or no highlight link_url -->
          <router-link
            v-if="!highlight.link_url"
            :to="highlight.product ? `/product/${highlight.product.slug}` : `/shop-by-brands/${model.slug}-official-store-${model.id}`"
          >
            <b-img ref="highlight" :src="(highlight.image_url || highlight.product && highlight.product.product_images[0].medium_image_url) + '?w=256'" :alt="setHighlightProductImgAltText(highlight)" fluid class="rounded" />
          </router-link>
          <router-link
            v-else-if="highlight.link_url && highlight.link_url.indexOf('whizliz.com') >= 0"
            :to="highlight.link_url.split('whizliz.com')[1] || `/shop-by-brands/${model.slug}-official-store-${model.id}`"
          >
            <b-img ref="highlight" :src="(highlight.image_url || highlight.product && highlight.product.product_images[0].medium_image_url) + '?w=256'" :alt="setHighlightProductImgAltText(highlight)" fluid class="rounded" />
          </router-link>
          <!-- else if not -->
          <a v-else :href="(highlight.link_url && !/^https?:\/\//.test(highlight.link_url) ? `https://${highlight.link_url}` : highlight.link_url)" target="_blank">
            <b-img ref="highlight" :src="(highlight.image_url || highlight.product && highlight.product.product_images[0].medium_image_url) + '?w=256'" :alt="setHighlightProductImgAltText(highlight)" fluid class="rounded" />
          </a>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BrandShowcaseHeader",
  props: ["brand"],
  data() {
    return {
      model: this.brand,
    }
  },
  methods: {
    setHighlightProductImgAltText(highlight) {
      return highlight.product?.name ? `${highlight.brand.name} ${highlight.product.name}` : '';
    },
  },
  watch: {
    brand(newVal) {
      this.model = newVal
    }
  }
}
</script>